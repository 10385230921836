import React, { useState } from "react";
import StringBuilder from "../helper/StringBuilder";
import {
    Alarmanlage,
    Brand,
    Tuerschloss,
    Videoueberwachung,
} from "./PriceCalculator/elements/Constants";
import { Link } from "gatsby";

const FloorPlan = ({ city }) => {
    //     { Gewerbe_IMG1 : "/img/Sicherheitsstufen_Gewerbe_Basis.jpg",
    //  Gewerbe_IMG2 : "/img/Sicherheitsstufen_Gewerbe_Premium.jpg",
    //  Gewerbe_IMG3 : "/img/Sicherheitsstufen_Gewerbe_Maximum.jpg",
    //  Haus_IMG1 : "/img/Sicherheitsstufen_Haus_Basis.jpg",
    //  Haus_IMG2 : "/img/Sicherheitsstufen_Haus_Premium.jpg",
    //  Haus_IMG3 : "/img/Sicherheitsstufen_Haus_Maximum.jpg",

    //  Wohnung_IMG1 : "/img/Sicherheitsstufen_Wohnung_Basis.jpg",
    //  Wohnung_IMG2 : "/img/Sicherheitsstufen_Wohnung_Premium.jpg",
    //  Wohnung_IMG3 : "/img/Sicherheitsstufen_Wohnung_Maximum.jpg",}
    const products = [
        [
            {
                title: "Basis",
                img: "/img/Sicherheitsstufen_Haus_Basis.jpg",
                text: 'Die Sicherheitsstufe "Basis" fokussiert sich auf die Absicherung des Innenraums sowie der Fenster und Türen Ihres Hauses und ermöglicht so eine Alarmierung im Falle eines Einbruchs.',
                list: [
                    "Alarmsirenen",
                    "Innenraumüberwachung",
                    "Fenster- und Türsensoren",
                    "Redundante Kommunikation",
                ],
                price: "Ab 1.999,- EUR",
                priceMonthly: "55",
            },
            {
                title: "Premium",
                img: "/img/Sicherheitsstufen_Haus_Premium.jpg",
                text: 'Die Sicherheitsstufe "Premium" ermöglicht durch die zusätzliche Überwachung der Fassaden Ihres Hauses eine frühere Einbruchserkennung und steht somit für noch höhere Sicherheit.',
                list: [
                    'Alle Inhalte der Sicherheitsstufe "Basis"',
                    "Panikalarm",
                    "Glasbruchmelder",
                    "Fassadenüberwachung",
                    "Fotokameras",
                    "Notrufleitstelle mit Polizeiruf",
                ],
                price: "Ab 2.799,- EUR",
                priceMonthly: "75",
            },
            {
                title: "Maximum",
                img: "/img/Sicherheitsstufen_Haus_Maximum.jpg",
                text: 'Mit der Sicherheitsstufe "Maximum" wird auch das Grundstück Ihres Hauses durch zusätzliche Alarmsysteme gesichert, wodurch eine frühzeitige Einbruchserkennung mit einer aktiven Abwehr von Einbrechern einhergeht.',
                list: [
                    'Alle Inhalte der Sicherheitsstufe "Premium"',
                    "Grundstücksüberwachung",
                    "Nebelkanonen",
                    "Gefahrenmelder",
                    "Sicherheitsdienst und Foto-Verifizierung",
                ],
                price: "Ab 3.999,- EUR ",
                priceMonthly: "105",
            },
        ],
        [
            {
                title: "Basis",
                img: "/img/Sicherheitsstufen_Wohnung_Basis.jpg",
                text: 'Die Sicherheitsstufe "Basis" fokussiert sich auf die Absicherung des Innenraums sowie der Fenster und Türen Ihrer Wohnung und ermöglicht so eine Alarmierung im Falle eines Einbruchs.',
                list: [
                    "Alarmsirenen",
                    "Innenraumüberwachung",
                    "Fenster- und Türsensoren",
                    "Redundante Kommunikation",
                ],
                price: "Ab 1.699,- EUR",
                priceMonthly: "49",
            },

            {
                title: "Premium",
                img: "/img/Sicherheitsstufen_Wohnung_Premium.jpg",
                text: 'Die Sicherheitsstufe "Premium" ermöglicht durch die zusätzliche Überwachung, z.B. von Fassaden oder Balkon Ihrer Wohnung eine frühere Einbruchserkennung und steht somit für noch höhere Sicherheit.',
                list: [
                    'Alle Inhalte der Sicherheitsstufe "Basis"',
                    "Panikalarm",
                    "Glasbruchmelder",
                    "Fassadenüberwachung",
                    "Fotokameras",
                    "Notrufleitstelle mit Polizeiruf",
                ],
                price: "Ab 2.549,- EUR",
                priceMonthly: "69",
            },
            {
                title: "Maximum",
                img: "/img/Sicherheitsstufen_Wohnung_Maximum.jpg",
                text: 'Mit der Sicherheitsstufe "Maximum" wird Ihre Wohnung durch zusätzliche Alarmsysteme gesichert, wodurch eine frühzeitige Einbruchserkennung mit einer aktiven Abwehr von Einbrechern einhergeht.',
                list: [
                    'Alle Inhalte der Sicherheitsstufe "Premium"',
                    // "Grundstücksüberwachung",
                    "Nebelkanonen",
                    "Gefahrenmelder",
                    "Sicherheitsdienst und Foto-Verifizierung",
                ],
                price: "Ab 3.749,- EUR ",
                priceMonthly: "99",
            },
        ],
        [
            {
                title: "Basis",
                img: "/img/Sicherheitsstufen_Gewerbe_Basis.jpg",
                text: 'Die Sicherheitsstufe "Basis" fokussiert sich auf die Absicherung des Innenraums sowie der Fenster und Türen Ihres Gewerbes und ermöglicht so eine Alarmierung im Falle eines Einbruchs.',
                list: [
                    "Alarmsirenen",
                    "Innenraumüberwachung",
                    "Fenster- und Türsensoren",
                    "Redundante Kommunikation",
                ],
                price: "Ab 1.399,- EUR",
                priceMonthly: "39",
            },

            {
                title: "Premium",
                img: "/img/Sicherheitsstufen_Gewerbe_Premium.jpg",
                text: 'Die Sicherheitsstufe "Premium" ermöglicht durch die zusätzliche Überwachung der Fassaden Ihres Gewerbes eine frühere Einbruchserkennung und steht somit für noch höhere Sicherheit.',
                list: [
                    'Alle Inhalte der Sicherheitsstufe "Basis"',
                    "Panikalarm",
                    "Glasbruchmelder",
                    "Fassadenüberwachung",
                    "Fotokameras",
                    "Notrufleitstelle mit Polizeiruf",
                ],
                price: "Ab 2.149,- EUR",
                priceMonthly: "59",
            },
            {
                title: "Maximum",
                img: "/img/Sicherheitsstufen_Gewerbe_Maximum.jpg",
                text: 'Mit der Sicherheitsstufe "Maximum" wird Ihr Gewerbe durch zusätzliche Alarmsysteme gesichert, wodurch eine frühzeitige Einbruchserkennung mit einer aktiven Abwehr von Einbrechern einhergeht.',
                list: [
                    'Alle Inhalte der Sicherheitsstufe "Premium"',
                    "Grundstücksüberwachung",
                    "Nebelkanonen",
                    "Gefahrenmelder",
                    "Sicherheitsdienst und Foto-Verifizierung",
                ],
                price: "Ab 3.149,- EUR ",
                priceMonthly: "85",
            },
        ],
    ];

    const segments = ["Haus", "Wohnung", "Gewerbe"];

    const [segmentIndex, setSegmentIndex] = useState(0);
    const [modalProductId, setModalProductId] = useState(0);

    const handleSegmentChange = (index) => {
        setSegmentIndex(index);
    };

    return (
        <div className="floor-plan">
            <h2 className="title tile-custome padding-12px-mob">
                Entdecken Sie unsere professionellen Sicherheitsstufen
            </h2>
            <h3 style={{ textAlign: "center" }}>
                Wir wissen, dass jeder Kunde unterschiedliche Wünsche und
                Bedürfnisse hat und konzipieren daher maßgeschneiderte Lösungen
                und Konzepte, die an die Anforderungen des jeweiligen Objekts
                angepasst sind. Um den Einstieg in die Planung zu erleichtern,
                haben wir spezielle Sicherheitsstufen entwickelt. Diese dienen
                als Orientierung und dabei können einzelne Bausteine beliebig
                zwischen den Stufen ausgetauscht werden.
            </h3>
            {/* <Modal show={showModal} product={products[modalProductId]} /> */}
            <div className="segment-control has-text-centered mb-0">
                {segments.map((segment, i) => (
                    <span
                        key={i}
                        // style={{
                        //     borderRadius:
                        //         i === 0 ? "25px 0 0 25px" : "0 25px 25px 0",
                        // }}
                        style={{ marginLeft: "5px", marginTop: "5px" }}
                        className={
                            "segment-control-btn has-text-weight-medium" +
                            (segmentIndex === i ? " selected" : "")
                        }
                        onClick={() => handleSegmentChange(i)}
                    >
                        {segment}
                    </span>
                ))}
            </div>
            <div
                className=" is-mobile grid-container "
                id="price-card-to-scroll"
            >
                {products[segmentIndex].map((item, index) => {
                    return (
                        <div className="grid-item">
                            <h3
                                class={`${
                                    index != 0 ? "price-card-title" : ""
                                }`}
                            >
                                {item.title}
                            </h3>
                            <img
                                src={item.img}
                                style={{ height: "10rem", width: "20rem" }}
                            />
                            <p>{item.text}</p> <br />
                            <p>Diese Sicherheitsstufe beinhaltet:</p>
                            <ul style={{ marginLeft: "20px" }}>
                                {item.list.map((listName) => {
                                    return <li>{listName}</li>;
                                })}
                            </ul>
                            <h3
                                style={{
                                    marginTop: "2rem",
                                    position: "absolute",
                                    // bottom: "-20px",
                                    bottom: "-80px",
                                    left: "0",
                                    right: "0",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "100%",
                                }}
                                className="price-card-price"
                            >
                                <b>{item.price}</b>
                                <br />
                                <small>
                                    oder alternativ ab {item.priceMonthly},- EUR
                                    monatlich
                                </small>
                            </h3>
                        </div>
                    );
                })}
            </div>
            <div className="centerButtonInDiv " style={{ marginTop: "5rem" }}>
                <Link
                    className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                    to="/preisrechner/"
                    id="btn_pricesecuritylevels_preisberechnen"
                >
                    Genauen Preis berechnen
                </Link>
            </div>
        </div>
    );
};

export default FloorPlan;
