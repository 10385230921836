import React from "react";
import PriceCalculationButton from "./PriceCalculator/PriceCalculationButton";
import StringBuilder from "../helper/StringBuilder";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby";
import Reviews from "../components/Reviews";
import PricingCard from "../components/PricingCard";

export default class HowTo2 extends React.Component {
    constructor(props) {
        super(props);
        // let path = "";
        // if (typeof window !== `undefined`) {
        //     path = window.location.pathname;
        // }
        // path = path.replace(/\//g, "");
        // console.log("path is", path);
        // console.log("path.indexOf", path.indexOf("/alarmanlagen"));
        // let showPriceCard = false;
        // if (path && Boolean(path.indexOf("/alarmanlagen"))) {
        //     showPriceCard = true;
        // } else {
        //     showPriceCard = false;
        // }
        // console.log("showPriceCard", showPriceCard);
        // this.state = {
        //     showPriceCard,
        // };
        if (typeof window !== "undefined") {
            const urlParams = new URLSearchParams(window.location.search);
            const pricing = urlParams.get("psl");
            if (pricing) {
                console.log("pricing trur");
                setTimeout(() => {
                    const section = document.querySelector(
                        "#price-card-to-scroll"
                    );
                    section.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                    });
                }, 1000);
            }
        }
    }
    render() {
        let showVideo = true;
        if (this.props.showVideo) {
            showVideo = this.props.showVideo == "false" ? false : true;
        }

        console.log("showVideo showVideo showVideoshowVideo", showVideo);

        const slug =
            typeof this.props.city !== "undefined"
                ? this.props.city.slug
                : this.props.city;
        let stringBuilder = new StringBuilder(
            this.props.city,
            this.props.product
        );
        let isMobile = typeof window != "undefined" && window.innerWidth <= 768;
        let icon3Route = "/img/image_3_wir_installieren_fuer_sie_Einbruch.svg";
        if (this.props.product) {
            icon3Route =
                "/img/image_3_wir_installieren_fuer_sie_" +
                this.props.product +
                ".svg";
        }
        return (
            <div>
                <div className="section how-to2">
                    <div className="body">
                        <div className="columns">
                            <div className="column has-text-centered">
                                <h2 className="title">
                                    In 3 einfachen Schritten zu{" "}
                                    {stringBuilder.getHowToTitle() +
                                        stringBuilder.getInStadtUndUmgebung()}
                                </h2>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column has-text-centered">
                                <div className="card">
                                    <div className="card-image">
                                        <Fade top>
                                            <figure className="image">
                                                <img
                                                    src="/img/image_1_bedarf_ermitteln.svg"
                                                    alt="Bedarf ermitteln"
                                                />
                                            </figure>
                                        </Fade>
                                    </div>
                                    <div className="card-content">
                                        <div className="content">
                                            <div className="title">
                                                1. Preis berechnen
                                            </div>
                                            <div className="text">
                                                Geben Sie die Informationen zu
                                                der Immobilie an, die Sie
                                                sichern möchten. Sie erhalten
                                                eine detaillierte
                                                Preiskalkulation für eine{" "}
                                                {stringBuilder.getSingularProductName() +
                                                    stringBuilder.getInStadtUndUmgebung()}
                                                .
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column has-text-centered">
                                <div className="card">
                                    <div className="card-image">
                                        <Fade top>
                                            <figure className="image">
                                                <img
                                                    src="/img/image_2_kostenlose_beratung.svg"
                                                    alt="Kostenlose Beratung"
                                                />
                                            </figure>
                                        </Fade>
                                    </div>
                                    <div className="card-content">
                                        <div className="content">
                                            <div className="title titlecustome">
                                                2. Sicherheitskonzept abstimmen
                                            </div>
                                            <div
                                                className="text"
                                                style={{
                                                    marginLeft: isMobile
                                                        ? "0px"
                                                        : "-28px",
                                                }}
                                            >
                                                Ob telefonisch oder bei Ihnen
                                                vor Ort - unsere
                                                Sicherheitsberater
                                                {stringBuilder.getAusStadtUndUmgebung()}{" "}
                                                stehen Ihnen bei allen Fragen
                                                rund um Ihre Sicherheit mit Rat
                                                und Tat zur Verfügung.
                                            </div>
                                            <div className="is-hidden-mobile">
                                                <div
                                                    style={{
                                                        marginTop: "30px",
                                                        fontFamily: "inherit",
                                                        fontWeight: "600",
                                                        color: "#7B7B90",
                                                        marginLeft: "-25px",
                                                    }}
                                                >
                                                    Die Schritte 1 und 2 bleiben
                                                    für Sie immer kostenfrei.
                                                </div>
                                                <div className="centerButtonInDiv ">
                                                    <Link
                                                        className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                                                        to="/preisrechner/"
                                                        id="btn_3steps_jetztstarten"
                                                    >
                                                        Jetzt starten
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="column has-text-centered">
                                <div className="card">
                                    <div className="card-image">
                                        <Fade top>
                                            <figure className="image">
                                                <img
                                                    src={icon3Route}
                                                    alt="Wir installieren für sie"
                                                />
                                            </figure>
                                        </Fade>
                                    </div>
                                    <div className="card-content">
                                        <div className="content">
                                            <div className="title">
                                                3. Wir installieren die Anlage
                                            </div>
                                            <div className="text">
                                                Lehnen Sie sich zurück und
                                                fühlen Sie sich sicher: Unser
                                                Team übernimmt auch die
                                                Installation Ihrer neuen{" "}
                                                {stringBuilder.getSingularProductName()}{" "}
                                                mit eigenen
                                                Sicherheitstechnikern.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="only-mobile">
                                <div
                                    style={{
                                        marginTop: "30px",
                                        fontFamily: "inherit",
                                        fontWeight: "600",
                                        color: "#7B7B90",
                                        textAlign: "center",
                                    }}
                                >
                                    Die Schritte 1 und 2 bleiben für Sie immer
                                    kostenfrei.
                                </div>
                                <div className="centerButtonInDiv ">
                                    <Link
                                        className="button preisrechner-btn preisrechner-btn-primary newHome3Button"
                                        to="/preisrechner/"
                                    >
                                        Jetzt starten
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        marginTop: "40px",
                        marginBottom: "40px",
                        backgroundColor: "#F2F2F2",
                    }}
                >
                    <Reviews />
                </div>

                <div
                    className="section how-to2 "
                    style={{ marginBottom: "5rem" }}
                >
                    {this.props.showPriceCard ? <PricingCard /> : ""}
                </div>
                {showVideo ? (
                    <div
                        className="section how-to2"
                        // style={{ marginTop: "60px" }}
                    >
                        <div className="has-text-centered">
                            <h2
                                className="title padding-12px-mob"
                                style={{
                                    marginBottom: "-20px",
                                    marginTop: "-20px",
                                }}
                            >
                                Erhalten Sie jetzt einen Einblick in unsere
                                professionellen Abläufe
                            </h2>
                            <br />
                        </div>
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe
                                className="ifrmvideo"
                                width="1268"
                                height="713"
                                src="https://www.youtube.com/embed/tsey55xtfX8"
                                title="MeinAlarm24 - So einfach und unkompliziert können wir Sie absichern!"
                                frameBorder="0"
                                allow="accelerometer; autoplay; 
                      clipboard-write; encrypted-media; gyroscope; 
                            picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className="columns">
                            <div className="column has-text-centered">
                                <PriceCalculationButton
                                    text="Jetzt starten"
                                    city={slug}
                                    id="btn_belowvid_jetztstarten"
                                    product={this.props.product}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }
}
